import Vue from "vue"
import VueRouter from "vue-router"
Vue.use(VueRouter);

export default new VueRouter({
  mode: 'hash',
  routes: [
    {
      path: '/',
      hidden: true,
      component: resolve => require(['@/page/login.vue'], resolve),
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'login',
      hidden: true,
      component: resolve => require(['@/page/login.vue'], resolve)
    },
    {
      path: "/home",
      component: resolve =>
        require(["@/page/home.vue"], resolve),
        // component: Layout,
      children: [
        {
          path: "/index/index",
          component: resolve =>
            require(["@/page/module/index/index.vue"], resolve),
          name: "首页",
          icon: "home",
        },
        {
          path: "/enquiry/enquiryList",
          component: resolve =>
            require(["@/page/module/enquiry/enquiryList.vue"], resolve),
          name: "报价管理",
          icon: "container",
        },
        {
          path: "/enquiry/detail",
          component: resolve =>
            require(["@/page/module/enquiry/detail.vue"], resolve),
          name: "报价详情",
          hidden: true,
          icon: "bars",
        },
        {
          path: "/quoteorder/quoteorderList",
          component: resolve =>
          require(["@/page/module/quoteorder/quoteorderList.vue"], resolve),
          name: "报价订单",
          icon: "profile",
        },
        {
          path: "/quoteorder/detail",
          component: resolve =>
          require(["@/page/module/quoteorder/detail.vue"], resolve),
          name: "报价订单详情",
          hidden: true,
          icon: "file-text",
        },
		{
		  path: "/transfer/transferorderList",
		  component: resolve =>
		  require(["@/page/module/transfer/transferorderList.vue"], resolve),
		  name: "转账记录",
		  icon: "wechat",
		},
		{
		  path: "/transfer/collectionorderList",
		  component: resolve =>
		  require(["@/page/module/transfer/collectionorderList.vue"], resolve),
		  name: "发代收记录",
		  icon: "setting",
		},
        
        // {
        //   path: "/enquiry",
        //   name: "询价单记录",
        //   component:{
        //     render (c) { return c('router-view') }
        //   },
        //   icon: "file-text",
        //   children: [
        //     {
        //       path: "/enquiry/enquiryList",
        //       component: resolve =>
        //         require(["@/page/module/enquiry/enquiryList.vue"], resolve),
        //       name: "询价单记录",
        //     }
        //   ]
        // },
        // {
        //   path: "/quoteorder",
        //   name: "报价订单",
        //   component:{
        //     render (c) { return c('router-view') }
        //   },
        //   icon: "file-text",
        //   children: [
        //     {
        //       path: "/quoteorder/quoteorderList",
        //       component: resolve =>
        //         require(["@/page/module/quoteorder/quoteorderList.vue"], resolve),
        //       name: "报价订单",
        //     }
        //   ]
        // },
        {
          path: "/shop/shopList",
          component: resolve =>
              require(["@/page/module/shop/shopList.vue"], resolve),
          name: "商品列表",
          icon: "block",
        },
        {
          path: "/order/orderList",
          component: resolve =>
          require(["@/page/module/order/orderList.vue"], resolve),
          name: "订单列表",
          icon: "file-text",
          
        },
        // {
        //   path: "/message",
        //   name: "留言管理",
        //   component:{
        //     render (c) { return c('router-view') }
        //   },
        //   icon: "mail",
        //   children: [
        //     {
        //       path: "/message/messageList",
        //       component: resolve =>
        //         require(["@/page/module/message/messageList.vue"], resolve),
        //       name: "留言列表",
        //     }
        //   ]
        // },

        {
          path: "/withdraw/withdrawList",
          component: resolve =>
          require(["@/page/module/withdraw/withdrawList.vue"], resolve),
          name: "提现记录",
          icon: "alipay",
        },

        
        {
          path: "/base/baseData",
          component: resolve =>
          require(["@/page/module/base/baseData.vue"], resolve),
          name: "基础设置",
          icon: "setting",
        },
        
        // {
        //   path: "/chat",
        //   name: "聊天demo",
        //   component:{
        //     render (c) { return c('router-view') }
        //   },
        //   icon: "setting",
        //   children: [
        //     {
        //       path: "/chat/chat",
        //       component: resolve =>
        //         require(["@/page/chat.vue"], resolve),
        //       name: "聊天",
        //     }
        //   ]
        // }
      ]
    },
    {
      path: '*',
      hidden: true,
      component: () => import('@/page/404.vue')
    }
  ]
})