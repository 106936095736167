import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    count:1,
    ordercount:1,
	transfercount:1,
    chatHide:false,
    contact:[],
  },
  mutations: {
    add(state) {      
      state.count++    
    },
    addOrderCount(state) {      
      state.ordercount++    
    },
	addTransferCount(state) {
	  state.transfercount++    
	},
    chatOpen(state) {      
      state.chatHide = !state.chatHide;   
    },
    setContact(state, contact) {      
      state.contact = contact;   
    }

  },
  actions: {
  },
  modules: {
  }
})
