import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);
import '@/assets/css/common.css'

import Print from 'vue-print-nb'
Vue.use(Print);

import LemonIMUI from 'lemon-imui';
import 'lemon-imui/dist/index.css';
Vue.use(LemonIMUI);


Vue.prototype.backPage = () => router.back(-1)

import Router from 'vue-router'
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

router.beforeEach(async (to, from, next) => {
  const { name } = to;
  if (name === 'login') {
    return next();
  }
  if (sessionStorage.getItem("user")) {
    return next();
  } else {
    return next({
      name: 'login',
      params: { back: to },
    });
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')